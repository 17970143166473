import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {
  UpcomingEvents,
  PageTitle,
} from "@components"

const Events = () => (
  <Layout>
    <Seo title="Events" />
    <PageTitle title="Events" />
    <UpcomingEvents />
  </Layout>
)

export default Events
